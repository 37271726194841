























import { RecepcionKtb,TransferirModel,TransferirPlanta } from '@/entidades/KTB/RegistroInformacion/RecepcionListadoQuery';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import { PlantaState } from '@/store/Maestros/Plantas/types';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {State,Action} from 'vuex-class';
@Component
export default class TransferirComponente extends Vue
{
    plantaId:number=1;
    @State('plantas',{namespace:'plantaModule'}) plantas!:Array<PlantaState>;
    @Prop({type:Object,required:true}) item!:RecepcionKtb
    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!: Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!: Function;

    async guardar()
    {
        try
        {
            this.changeLoading(new Loading(true,"Espere por favor..."));
            let rsp = await TransferirPlanta(new TransferirModel(Number(this.item.id),this.usuarioId,Number(this.plantaId)));
            if(rsp.isSuccess == true)
            {
                this.$emit('limpiar');
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",1000));
            }
        }
        catch(error)
        {
            this.changeAlerta(new Alerta(error,true,"danger",1000))
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
}
